<template>
  <div>
    <basic-container>
      <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page.sync="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
        <template slot="status" slot-scope="scope">
          <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                     @change="change(scope.row)">
          </el-switch>
        </template>
        <template slot="menuRight">
          <el-select v-model="status" :placeholder="$t('STATE')" size="small"
                     style="width:100px !important;marginRight: 5px;" clearable>
            <el-option :label="$t('NORMAL')" value="0">{{ $t('NORMAL') }}</el-option>
            <el-option :label="$t('DISABLE')" value="1">{{ $t('DISABLE') }}</el-option>
          </el-select>
          <el-select clearable v-model="processId" filterable :placeholder="$t('WORKING PROCEDURE')" size="small"
                     style="width:120px !important;marginRight: 5px;">
            <el-option v-for="item in wkln" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list, add, edit, wklnList, shiftsTypeInit, changeStatus} from "@/api/shifts"

export default {
  data() {
    return {
      tableLoading: false,
      nameOrCode: "",
      wkln: [],
      shiftsTypeList: [],
      status: '',
      processId: '',
      page: {
        pageSize: 20,
        pagerCount: 5,
        total: 10,
        pageCurrent: 1
      },
      disabled: true,
      tableData: [],
      tableOption: {
        delBtn: false,
        menuWidth: 200,
        selection: true,
        tip: false,
        columnBtn: false,
        dialogFullscreen: true,
        addBtnText: this.$t('ADD'),
        delBtnText: this.$t('DELETE'),
        editBtnText: this.$t('Edit'),
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [{
          label: this.$t('Shifts Name'),
          prop: "name",
          editDisabled: true,
          rules: [{
            required: true,
            message: this.$t('PLEASE ENTER A NAME'),
            trigger: "blur"
          }]
        }, {
          label: this.$t('Shifts Type'),
          editDisabled: true,
          type: "select",
          prop: "type",
          props: {
            label: "name",
            value: "code"
          },
          dicData: [],
          rules: [{
            required: true,
            message: "请选择班制",
            trigger: "change"
          }],
        }, {
          label: this.$t('START TIME'),
          prop: "beginTime",
          valueFormat: "HH:mm",
          type: "time",
          format: 'HH:mm',
          rules: [{
            required: true,
            message: "请选择开始时间",
            trigger: "change"
          }],
        }, {
          label: this.$t('END TIME'),
          prop: "endTime",
          valueFormat: "HH:mm",
          type: "time",
          format: 'HH:mm',
          rules: [{
            required: true,
            message: "请选择结束时间",
            trigger: "change"
          }],
        }, {
          label: this.$t('Working hours'),
          type: "number",
          prop: "workingHours",
          rules: [{
            required: true,
            message: "请输入工时",
            trigger: "blur"
          }],
        }, {
          label: this.$t('Status'),
          prop: "status",
          type: "switch",
          dicData: [{
            label: this.$t('DISABLE'),
            value: 1
          }, {
            label: this.$t('ENABLE'),
            value: 0
          }],
          rules: [{
            required: true,
            message: "请选择状态",
            trigger: "change"
          }],
        }, {
          label: this.$t('WORKING PROCEDURE'),
          editDisabled: true,
          type: "select",
          prop: "processId",
          props: {
            label: "name",
            value: "id"
          },
          rules: [{
            required: true,
            message: "请选择工序",
            trigger: "change"
          }],
          dicData: []
        }]
      }
    }
  },
  created() {
    shiftsTypeInit().then(res => {
      console.log(res);
      this.tableOption.column[1].dicData = res.data.data;
    });
    wklnList().then(res => {
      console.log(res)
      this.tableOption.column[6].dicData = res.data.data.items;
      this.wkln = res.data.data.items;
    });
    console.log(this.wkln);
    this.list();
  },
  methods: {
    currentChange(pageCurrent) {
      this.page.pageCurrent = pageCurrent;
      this.list();
    },
    sizeChange(pageSize) {
      this.page.pageCurrent = 1;
      this.page.pageSize = pageSize;
      this.list();
    },
    refreshData() {
      this.list();
    },
    handleDel(row) {
      this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        remove(row.id).then(res => {
          res.data.code == "0000" ? this.$message({
            type: "success",
            message: this.$t('DeleteSuccess')
          }) : "";
          this.list();
        })
      })
    },
    handleUpdate(row, index, done) {
      //row.colorValue = hexify(row.colorValue);
      edit(row).then(res => {
        res.data.code == "0000" ? this.$message({
          type: "success",
          message: this.$t('EDIT SUCCEEDED')
        }) : "";
        this.list();
        done();
      })
    },
    handleSave(row, done) {
      //row.colorValue = hexify(row.colorValue);
      console.log(row)
      add(row).then(res => {
        console.log(res, '181')
        if (res.data.code == '0000') {
          this.$message({message: res.data.msg, type: "success"})
        }
        this.list();
        done();
      })
    },
    change(row) {
      changeStatus(row).then(res => {
        if (res.data.code == "0000") {
          this.$message({
            type: "success",
            message: "状态修改成功"
          })
        }
      })
    },
    selectionChange(list) {
      console.log(list);
      list != "" ? this.disabled = false : this.disabled = true;
    },
    refreshChange() {
      this.list();
    },
    list() {
      this.tableData = [];
      list(this.page.pageCurrent, this.page.pageSize, this.processId, this.status).then(res => {
        if (res.data.data == null) {
          this.tableData = [];
          this.page.total = 0;
        } else {
          this.page.total = res.data.data.total;
          //res.data.data.items.forEach(v => {
          //  v.colorValue = hexToRgba(v.colorValue);
          //});
          this.tableData = res.data.data.items;
          this.tableData.forEach(v => {
            v.status = v.status;
          })
        }
      })
    },
    envText: function () {
      return this.env
    }
  }
}
</script>
<style scoped>
.el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  padding-left: 10px;
}
</style>
