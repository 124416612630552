var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "inactive-value": 1, "active-value": 0 },
                        on: {
                          change: function($event) {
                            return _vm.change(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        placeholder: _vm.$t("STATE"),
                        size: "small",
                        clearable: ""
                      },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { label: _vm.$t("NORMAL"), value: "0" } },
                        [_vm._v(_vm._s(_vm.$t("NORMAL")))]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: _vm.$t("DISABLE"), value: "1" } },
                        [_vm._v(_vm._s(_vm.$t("DISABLE")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "120px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: _vm.$t("WORKING PROCEDURE"),
                        size: "small"
                      },
                      model: {
                        value: _vm.processId,
                        callback: function($$v) {
                          _vm.processId = $$v
                        },
                        expression: "processId"
                      }
                    },
                    _vm._l(_vm.wkln, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }