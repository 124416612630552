import request from '@/router/axios';
import {businessUrl, userUrl} from "@/config/env"
export const list = (pageNo,pageSize,processId,status) => {
    return request({
        url : businessUrl + "shift/selectPage",
        method: "post",
        data: {
            pageNo,
            pageSize,
            processId,
            status
        }
    })
}
//编辑
export const edit = (row) => {
    return request({
        url : businessUrl + "shift/update",
        method: "put",
        data: {
            ...row
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "shift/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const add = (row) => {
    return request({
        url : businessUrl + "shift/insert",
        method: "post",
        data : {
            ...row,
        }
    })
}

export const wklnList = () => {
    return request({
        url : businessUrl + "process/selectPage",
        method: "post",
        data: {
        }
    })
}
//班制类别下拉框初始化
export const shiftsTypeInit = () => {
    return request({
        url : userUrl + "dict/selectDictByCategoryId",
        method: "get",
        params: {
            categoryId: "shiftsType"
        }
    })
}

export const changeStatus = (row) => {
    return request({
        url : userUrl + "shift/updateStatus",
        method: "put",
        data: {
            ...row
        }
    })
}